import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import VisionProposito from "../components/VisionProposito";
import Servicios from "../components/Servicios";
import Contacto from "../components/Contacto";
import fondo from "../assets/images/fondo.jpg";
import vector2 from "../assets/images/vector2.png";
import Partners from "../components/Partners";

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#services") {
      setTimeout(() => {
        document.getElementById("services")?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  }, [location]);

  return (
    <div>
      {/* Primera sección con fondo */}
      <div
        style={{
          backgroundImage: `url(${fondo})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "70vh",
          position: "relative",
          zIndex: 10,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "auto",
          }}
        >
          <img
            src={vector2}
            alt="Decorativo"
            loading="lazy"
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>

      {/* Sección Visión Propósito */}
      <VisionProposito />

      {/* Secciones siguientes */}
      <Servicios />
      <Partners />
      <Contacto />
    </div>
  );
};

export default Home;
