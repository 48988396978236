import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ServiceDetails from './pages/ServiceDetails';

const App = () => {
  return (
    <Router>
      <div className="relative">
        {/* Header siempre visible */}
        <div
          style={{
            position: 'absolute',
            width: '100%',
            zIndex: 50, // Asegura que esté encima del contenido
          }}
        >
          <Header />
        </div>

        {/* Contenido dinámico basado en las rutas */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service/:id" element={<ServiceDetails />} />
        </Routes>

        {/* Footer siempre visible */}
        <Footer />
      </div>
    </Router>
  );
};

export default App;
