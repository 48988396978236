import React from 'react';
import security from '../assets/images/security.png'; // Imagen para Security
import modern from '../assets/images/modern.png'; // Imagen para Modern Work
import infra from '../assets/images/Solution-Partner-Infrastructure.webp'; // Imagen para Infrastructure Azure
import on from '../assets/images/solution.png'; // Imagen para OnSolution

const Partners = () => {
  return (
    <section
      className="py-20 bg-[#001E67]" // Fondo azul
    >
      <div className="container mx-auto px-4">

        {/* Grid de partners */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 text-center">
          {/* Partner: ON */}
          <div className="flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
              <img
                src={on}
                alt="OnSolution Partner"
                className="w-64 h-32 object-contain mx-auto"
              />
            </div>
          </div>

          {/* Partner: Security */}
          <div className="flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
              <img
                src={security}
                alt="Security Partner"
                className="w-64 h-32 object-contain mx-auto"
              />
            </div>
          </div>

          {/* Partner: Modern Work */}
          <div className="flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
              <img
                src={modern}
                alt="Modern Work Partner"
                className="w-64 h-32 object-contain mx-auto"
              />
            </div>
          </div>

          {/* Partner: Infrastructure Azure */}
          <div className="flex items-center justify-center">
            <div className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
              <img
                src={infra}
                alt="Infrastructure Azure Partner"
                className="w-64 h-32 object-contain mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;