import React from "react";
import { Link } from "react-router-dom"; // Importar Link para la redirección
import elite from "../assets/images/elite.webp"; // Imagen para 365 Elite Services
import secure from "../assets/images/secure.webp"; // Imagen para 365 Secure Solutions
import learning from "../assets/images/learning.webp"; // Imagen para OnSolution Learning
import licensing from "../assets/images/licensing.webp"; // Imagen para Microsoft Licensing
import vector2 from "../assets/images/vector2.png"; // Vector decorativo
import patronServicios from "../assets/images/patronservicios.png"; // Fondo decorativo superior

const Servicios = () => {
  const servicios = [
    { id: "365-elite-services", name: "365 Elite Services", image: elite },
    { id: "365-secure-solutions", name: "365 Secure Solutions", image: secure },
    { id: "onsolution-learning", name: "OnSolution Learning", image: learning },
    {
      id: "microsoft-licensing",
      name: "Microsoft Licensing",
      image: licensing,
    },
  ];

  return (
    <section id="services"
      className="bg-white relative py-12"
      style={{
        minHeight: "700px",
      }}
    >
      {/* Fondo decorativo en la parte superior */}
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "50%",
          backgroundImage: `url(${patronServicios})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0,
        }}
      ></div>

      {/* Vector decorativo arriba */}
      <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "auto",
          transform: "rotate(180deg)",
          zIndex: 1,
        }}
      >
        <img
          src={vector2}
          alt="Decorativo"
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      </div>

      {/* Contenido principal */}
      <div className="relative z-10 mt-24">
        <div className="flex items-center justify-between mb-12">
          {/* Espacio vacío a la izquierda */}
          <div className="flex-1"></div>

          {/* Título estilizado a la derecha */}
          <div
            className="border-2 border-[#001A5A] px-6 py-4 relative"
            style={{
              borderRadius: "9999px",
              display: "inline-block",
              backgroundColor: "white",
            }}
          >
            <h2 className="text-[#001A5A] text-4xl font-bold">
              Nuestros Servicios
            </h2>
          </div>
        </div>

        {/* Servicios */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12 text-center">
          {servicios.map((servicio) => {
            const [firstLine, ...secondLine] = servicio.name.split(" "); // Divide las palabras
            return (
              <Link
                to={`/service/${servicio.id}`} // Redirección al servicio
                key={servicio.id}
                className="flex flex-col items-center hover:scale-105 transition-transform"
              >
                {/* Imagen con contorno suavizado */}
                <div
                  className="mb-4 p-4 rounded-full border-4 border-[#001A5A] flex items-center justify-center"
                  style={{
                    backgroundColor: "white", // Fondo blanco detrás de la imagen
                  }}
                >
                  <img
                    src={servicio.image}
                    alt={servicio.name}
                    className="w-48 h-48 object-contain rounded-full"
                  />
                </div>

                {/* Título del servicio en dos líneas */}
                <div className="text-[#001A5A] font-semibold text-lg">
                  <p className="text-xl font-bold">{firstLine}</p>
                  <p className="text-lg">{secondLine.join(" ")}</p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Servicios;
