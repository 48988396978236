import React from 'react';
import visionImage from '../assets/images/vision.webp';
import missionImage from '../assets/images/mision.webp';

const VisionProposito = () => {
  const content = [
    {
      title: "Nuestra Visión",
      text: "Ser el Partner elegido por el valor agregado que entregamos a nuestros clientes mediante soluciones innovadoras y de alta calidad.",
      image: visionImage,
    },
    {
      title: "Nuestro Propósito",
      text: "Ofrecer Soluciones y Servicios para la adopción de la transformación digital, que permitan a las organizaciones maximizar el potencial de sus colaboradores, por medio del uso eficiente de los recursos económicos, la tecnología ajustada y el valor agregado provisto por nuestros especialistas.",
      image: missionImage,
      reverse: true,
    },
  ];

  return (
    <section className="relative py-8 bg-[#E2E6FF]" style={{ zIndex: 20 }}>
      <div className="container mx-auto px-4">
        {content.map((item, index) => (
          <div key={index} className={`flex flex-col md:flex-row ${item.reverse ? 'md:flex-row-reverse' : ''} items-center justify-between gap-6 mb-12`}>
            <div className="md:w-1/2 text-left">
              <h2 className="text-3xl font-bold mb-3 text-[#001A5A]">{item.title}</h2>
              <p className="text-lg text-gray-700 leading-relaxed">{item.text}</p>
            </div>
            <div className="md:w-1/2 flex justify-center">
              <div className="rounded-full border-4 border-white shadow-lg overflow-hidden w-48 h-48">
                <img src={item.image} alt={`Imagen de ${item.title}`} className="w-full h-full object-cover" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default VisionProposito;