import React from 'react';

const Footer = () => {
  return (
    <footer
      className="bg-[#001A5A] text-white py-4 px-8 text-center"
      style={{
        position: 'relative',
        bottom: 0,
        width: '100%',
      }}
    >
      <div className="text-sm">
        &copy; {new Date().getFullYear()} OnSolution Services&reg;. Todos los derechos reservados.
      </div>
      <div className="text-sm mt-2">
        Powered by{' '}
        <a
          href="https://tridniostudios.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-orange-500 hover:underline"
        >
          Tridnio Studios
        </a>
      </div>
    </footer>
  );
};

export default Footer;
