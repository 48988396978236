import React from "react";
import { useParams } from "react-router-dom";

const serviceData = {
  "365-elite-services": {
    title: "365 Elite Services",
    description:
      "Nuestro servicio administrado especializado en soluciones de tecnología en la nube está diseñado para transformar la manera en que operas, optimizando tus procesos, mejorando la seguridad y reduciendo costos. Con años de experiencia gestionando servicios en la nube, nuestro equipo de expertos no solo administra tu infraestructura, sino que también trabaja contigo para entender tus necesidades específicas y ofrecer soluciones personalizadas. Desde la implementación de Infraestructura de la nube hasta la integración de servicios de productividad, colaboración y la gestión de bases de datos y aplicaciones en la nube, estamos aquí para apoyarte en cada paso del camino. Con nosotros, llevar tu negocio al siguiente nivel es más sencillo y eficiente que nunca.",
  },
  "365-secure-solutions": {
    title: "365 Secure Solutions",
    description:
      "La seguridad en la nube es fundamental en el mundo digital actual. Con 365 Secure Solutions, puedes proteger tus datos y operaciones contra amenazas cibernéticas, vulnerabilidades, phishing y spam. Nuestro equipo, con años de experiencia en soluciones de seguridad te garantiza un entorno seguro y protegido. Confía en nuestras soluciones y nuestra experiencia para mantener tu información a salvo.",
  },
  "onsolution-learning": {
    title: "OnSolution Learning",
    description:
      "Somos tu partner de confianza para la capacitación en tecnologías Microsoft de alto nivel en la región. Nuestros cursos están diseñados y dictados por especialistas certificados, garantizando una experiencia de aprendizaje integral y efectiva. Nuestra oferta cuenta con contenidos que se adapta a las necesidades y objetivos específicos de organización.",
  },
  "microsoft-licensing": {
    title: "Microsoft Licensing",
    description:
      "Somos tu socio ideal para adquirir el licenciamiento de Microsoft en la región. Con nuestro equipo de especialistas en licenciamiento Microsoft, brindamos asesoría personalizada  hecha a la medida de tus necesidades. Nos destacamos por ofrecer un soporte postventa de primer nivel, asegurando que siempre tengas el respaldo y la asistencia que necesitas para maximizar el valor de tus inversiones en tecnología. Confía en nosotros para gestionar tus licencias de manera eficiente y eficaz.",
  },
};

const ServiceDetails = () => {
  const { id } = useParams();
  const service = serviceData[id];

  if (!service) {
    return (
      <div
        className="px-8 bg-gray-100 min-h-screen flex items-center justify-center text-center"
        style={{
          paddingTop: "100px", // Espacio extra para el header
        }}
      >
        <h1 className="text-5xl font-bold text-red-600 mb-6">
          Servicio no encontrado
        </h1>
        <p className="text-lg text-gray-600">
          Parece que el servicio que buscas no existe. Por favor, verifica la
          URL o selecciona otro servicio.
        </p>
      </div>
    );
  }

  return (
    <div
      className="bg-gray-50 min-h-screen flex flex-col justify-center items-center px-6 md:px-20"
      style={{
        paddingTop: "150px", // Espacio extra para asegurar que no estorbe el header
      }}
    >
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
        {/* Imagen destacada */}
        <div className="h-64 bg-[#001A5A] flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl font-extrabold">
            {service.title}
          </h1>
        </div>

        {/* Contenido del servicio */}
        <div className="p-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">
            Detalles del Servicio
          </h2>
          <p className="text-lg text-gray-600">{service.description}</p>
        </div>
      </div>

      {/* Botón de regreso */}
      <div className="mt-6">
        <a
          href="/#services"
          className="inline-block px-6 py-3 text-lg font-medium text-[#001A5A] border border-[#001A5A] rounded-full hover:bg-blue-600 hover:text-white transition"
        >
          Volver al inicio
        </a>
      </div>
    </div>
  );
};

export default ServiceDetails;
