import React from 'react';
import { FaEnvelope, FaInstagram, FaFacebookF, FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import logo from '../assets/images/logo.png'; // Ruta del logo

const Header = () => {
  return (
    <header
      className="text-white flex items-center justify-between px-8 py-6"
      style={{
        background: `linear-gradient(
          to bottom,
          rgba(0, 30, 103, 1) 70%, /* Color sólido */
          rgba(0, 30, 103, 0) 100%  /* Transparente */
        )`,
        height: '150px',
        position: 'absolute', // Posiciona el header encima del fondo
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 10,
      }}
    >
      {/* Logo */}
      <div className="flex items-center">
        <img src={logo} alt="OnSolution Services Logo" className="h-28 w-auto" /> {/* Logo más grande */}
      </div>

      {/* Iconos de redes sociales */}
      <div className="flex space-x-6 text-2xl">
        <a
          href="https://www.linkedin.com/company/onsolutionservices/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          <FaLinkedinIn />
        </a>
        <a
          href="https://www.instagram.com/onsolution_services/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.linkedin.com/company/onsolutionservices/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-300"
        >
          <FaFacebookF />
        </a>
      </div>
    </header>
  );
};

export default Header;
