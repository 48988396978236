import React from "react";
import fondoContacto from "../assets/images/fondocontacto.png"; // Ruta del fondo general
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"; // Importación de íconos

const banderas = [
  { pais: "USA", src: "https://flagcdn.com/w80/us.png" },
  { pais: "Colombia", src: "https://flagcdn.com/w80/co.png" },
  { pais: "Costa Rica", src: "https://flagcdn.com/w80/cr.png" },
  { pais: "Panamá", src: "https://flagcdn.com/w80/pa.png" },
  { pais: "Guatemala", src: "https://flagcdn.com/w80/gt.png" },
  { pais: "República Dominicana", src: "https://flagcdn.com/w80/do.png" },
];

const Contacto = () => {
  return (
    <section
      id="contacto"
      className="py-16 relative"
      style={{
        backgroundImage: `url(${fondoContacto})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 items-center w-full">
        {/* Columna de Información */}
        <div
          className="p-8 text-white flex flex-col justify-between"
          style={{
            backgroundColor: "#001E67",
            borderRadius: "0 40px 40px 0", // Bordes redondeados solo a la derecha
            border: "2px solid #001E67",
            height: "100%", // Ambos cards tendrán la misma altura
          }}
        >
          <h2 className="text-3xl font-bold mb-6">Contáctanos</h2>
          <div className="flex space-x-4">
            {banderas.map((bandera, index) => (
              <img
                key={index}
                src={bandera.src}
                alt={bandera.pais}
                className="w-12 h-8"
              />
            ))}
          </div>
          
          <p className="text-lg mt-4">
            📞 Costa Rica:{" "}
            <a
              href="tel:+50640001873"
              className="underline text-blue-300 hover:text-blue-400"
            >
              +506 4000-1873
            </a>
          </p>
          <p className="text-lg">
            📞 Colombia:{" "}
            <a
              href="tel:+573009291460"
              className="underline text-blue-300 hover:text-blue-400"
            >
              +57 300 929 1460
            </a>
          </p>
          <p className="text-lg mt-4">
            <a
              href="mailto:info@onsolutionservices.com"
              className="underline text-blue-300 hover:text-blue-400"
            >
              info@onsolutionservices.com
            </a>
          </p>
          {/* Iconos de redes sociales */}
          <div className="flex space-x-4 mt-6">
            <a
              href="https://www.facebook.com/onsolutionservices"
              className="text-white text-2xl hover:text-gray-300"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/onsolution_services/"
              className="text-white text-2xl hover:text-gray-300"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/onsolutionservices/"
              className="text-white text-2xl hover:text-gray-300"
            >
              <FaLinkedinIn />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contacto;
